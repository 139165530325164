/* Small mobile device mixin */
@mixin smallMobile {
  @media only screen and (max-width: 575px) {
    @content;
  }
}

/* Mobile device mixin */
@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

/* Tablet device mixin */
@mixin tablet {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

/* Desktop device mixin */
@mixin desktop {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

/* large devices mixin */
@mixin xDesktop {
  @media only screen and (max-width: 1399px) {
    @content;
  }
}

/* 2x  large devices mixin */
@mixin xxDesktop {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}

/* 3x  large devices mixin */
@mixin xxxDesktop {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

/* Fade color animation */
@mixin fadeColor($from, $to) {
  @keyframes fadeColor {
    from {
      background-color: $from;
    }
    to {
      background-color: $to;
    }
  }
}

/* Override Spinner background (Make sure to set `className={styles.spinner}`) */
@mixin spinnerColor($color) {
  .spinner {
    div:after {
      background: var($color);
    }
  }
}

@mixin spinner($color) {
  @keyframes spin {
    100% {
      transform: rotate(1turn);
    }
  }

  width: 100%;
  height: 100%;
  border-radius: 100px;
  background:
    radial-gradient(farthest-side, $color 94%, transparent) top / 4.5px 4.5px,
    radial-gradient(farthest-side, $color 94%, transparent) left / 4.5px 4.5px,
    radial-gradient(
        farthest-side at bottom left,
        transparent calc(100% - 5px),
        $color calc(100% - 4.5px) 99%,
        transparent
      )
      top right / 50% 50%,
    radial-gradient(
        farthest-side at top,
        transparent calc(100% - 5px),
        $color calc(100% - 4.5px) 99%,
        transparent
      )
      bottom / 100% 50%;
  background-repeat: no-repeat;
  animation: spin 1.5s infinite linear;
}

@mixin textEllipsis($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

$screenFragments: 12;
$screenWidthUnit: calc(100 / $screenFragments);

@mixin column($size: 12) {
  flex: 0 0 #{$screenWidthUnit * $size}#{'%'};
  max-width: #{$screenWidthUnit * $size}#{'%'};
}

@function viewHeightUnits($units: 1) {
  @return calc(var(--vh, 1vh) * $units);
}

/* Safari Browsers */
@mixin safari {
  @media not all and (min-resolution: 0.001dpcm) {
    @content;
  }
}

@mixin hideScrollBar() {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
